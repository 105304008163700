import React, { useState, useRef, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Row, Col, Container, Button } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import parse from 'html-react-parser';
import axios from 'axios';
import CountUp from 'react-countup';
import _ from "lodash"

import "./CountBlock.scss";

import { inViewOptions3, containerVariants, titleVariants, contentItem, fadeInFromTop } from '../../utils/animation';

import { motion } from "framer-motion";
import { InView, useInView } from "react-intersection-observer";



const COUNT_UP_START = 0;
const COUNT_UP_DURATION = 3;

const CountBlock = (props) => {

  const [loading, setloading] = useState(false);
  const [rowfirst, setRowFirst] = useState(false);
  const [countlist, setCountList] = useState(null);

 //useEffect(() => {
  //  getStatsData();
  //}, []);


const data = useStaticQuery(graphql`
  query MyQuery {
    allBranches(filter: {brand_id: {eq: "da"}}) {
      cash_buyers: sum(field: cash_buyers),
      first_time_buyers: sum(field: first_time_buyers),
      gross_value_sold: sum(field: gross_value_sold),
      mortgages_arranged_value: sum(field: mortgages_arranged_value),
      nothing_to_sell: sum(field: nothing_to_sell),
      over_60s: sum(field: over_60s),
      own_to_sell: sum(field: own_to_sell),
      total_applicants: sum(field: total_applicants),
      under_offer: sum(field: under_offer),
      valuations: sum(field: valuations),
      viewings_arranged: sum(field: viewings_arranged)
    }
  }
`)

  const statsdata = data.allBranches;


  //const getStatsData = async () => {
  //  const property_details = await axios.post(`${process.env.GATSBY_STRAPI_FEED_STATS_URL}`, { "brand_id": `da` }, {
  //    headers: { "Authorization": `Bearer ${process.env.GATSBY_STRAPI_FEED_STATS_TOKEN}` }
  //  }).then((result) => {
  //    if (result.data) {
  //      setloading(false)
  //      setStatsData(result.data);
  //    }
  //  }).catch((err) => {
  //    setloading(false)
  //  });
  //};

  if (!loading && statsdata) {
    if (statsdata.gross_value_sold > 450000000 || statsdata.mortgages_arranged_value > 1000000000) {
      if (!rowfirst) {
        setRowFirst(true)
      }
    }
    let list = [];
    if (statsdata.viewings_arranged >= 30000) {
      list.push({ value: statsdata.viewings_arranged, label: "viewings arranged in the last 12 months" })
    }
    if (statsdata.valuations >= 4000) {
      list.push({ value: statsdata.valuations, label: "homes valued in the last 12 months" })
    }
    if (statsdata.total_applicants >= 22000) {
      list.push({ value: statsdata.total_applicants, label: "active buyers registered with us" })
    }
    if (statsdata.cash_buyers >= 150) {
      list.push({ value: statsdata.cash_buyers, label: "cash buyers registered with us" })
    }
    if (statsdata.nothing_to_sell >= 150) {
      list.push({ value: statsdata.nothing_to_sell, label: "buyers ready to proceed" })
    }
    if (statsdata.first_time_buyers >= 150) {
      list.push({ value: statsdata.first_time_buyers, label: "first-time buyers registered with us" })
    }
    if (statsdata.under_offer >= 150) {
      list.push({ value: statsdata.under_offer, label: "buyers registered with us with their property under offer" })
    }
    if (statsdata.own_to_sell >= 150) {
      list.push({ value: statsdata.own_to_sell, label: "active buyers registered with us with a property to sell" })
    }
    // if (statsdata.over_60s >= 150) {
    //   list.push({ value: statsdata.over_60s, label: "buyers over 60, eligible for a Home for Life plan" })
    // }
    if (list.length > 0 && !countlist) {
      setCountList(_.shuffle(list))
    }
    // console.log("ccc", countlist)
  }

  if (loading) return (
    <section className={"count-section loading"}>
      Please wait loading stats
    </section>
  );

  function addZero(i) {
    if (i < 10) {i = "0" + i}
    return i;
  }


  var currentdate = new Date();
  var datetime = currentdate.getDate() + "/"
    + (currentdate.getMonth() + 1) + "/"
    + currentdate.getFullYear() + " @ "
    + addZero(currentdate.getHours()) + ":"
    + addZero(currentdate.getMinutes()) + ":"
    + addZero(currentdate.getSeconds());


  return (
    <InView {...inViewOptions3}>
      {({ ref, inView }) => (
        <motion.div
          className="count-section components--Home--CountBlock--CountBlock"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}

        >
          <Container>
            <Row>
              <Col md={12} lg="11" className="ms-auto">
                <motion.div variants={fadeInFromTop} className="block-heading">
                  {props.Stats_Title &&
                    <h2>{parse(props.Stats_Title)}</h2>
                  }
                </motion.div>
              </Col>
              <Col md={12} lg="11" className="ms-auto">
                {rowfirst ?
                  <div className="count-row count-row-first">
                    {statsdata.gross_value_sold > 1000000000 &&
                      <motion.div variants={fadeInFromTop} className="count-block">
                        <strong>
                          <CountUp
                            start={inView ? COUNT_UP_START : statsdata.gross_value_sold}
                            end={statsdata.gross_value_sold}
                            delay={0}
                            duration={COUNT_UP_DURATION}
                            prefix="£ "
                            separator=","
                          />
                        </strong>
                        <span>worth of properties sold in the last 12 months</span>
                      </motion.div>
                    }
                    {statsdata.mortgages_arranged_value > 1000000000 &&
                      <motion.div variants={fadeInFromTop} className="count-block">
                        <strong>
                          <CountUp
                            start={inView ? COUNT_UP_START : statsdata.mortgages_arranged_value}
                            end={statsdata.mortgages_arranged_value}
                            delay={0}
                            duration={COUNT_UP_DURATION}
                            prefix="£ "
                            separator=","
                          />
                        </strong>
                        <span>worth of mortgages arranged in the last 12 months</span>
                      </motion.div>
                    }
                  </div> :
                  <div className="count-row count-row-first">
                    {countlist && countlist.slice(0, 2).map((val, index) => {
                      return (
                        <motion.div variants={fadeInFromTop} className="count-block one-main">
                          <strong>
                            <CountUp
                              start={inView ? COUNT_UP_START : val.value}
                              end={val.value}
                              delay={0}
                              duration={COUNT_UP_DURATION}
                              // prefix="£ "
                              separator=","
                            />
                          </strong>
                          <span>{val.label}</span>
                        </motion.div>
                      )
                    })}
                  </div>
                }


                <div className="count-row count-row-last">
                  {countlist && countlist.slice(rowfirst ? 0 : 2, rowfirst ? 3 : 5).map((val, index) => {
                    return (
                      <motion.div key={2 + index} custom={3 + index} variants={fadeInFromTop} className="count-block">
                        <strong ref={ref}>
                          <CountUp
                            start={inView ? COUNT_UP_START : val.value}
                            end={val.value}
                            delay={0}
                            duration={COUNT_UP_DURATION}
                            separator=","
                          />
                        </strong>
                        <span>{val.label}</span>
                      </motion.div>
                    )
                  })}

                </div>
                <p><b>Source:</b> Arun Estates Ltd, correct as of {datetime}</p>
              </Col>
            </Row>
          </Container>
        </motion.div>
      )}
    </InView>
  );
};

export default CountBlock;
